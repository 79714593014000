import { render, staticRenderFns } from "./ResourceSearch.vue?vue&type=template&id=6e581c5a&scoped=true"
import script from "./ResourceSearch.vue?vue&type=script&lang=js"
export * from "./ResourceSearch.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6e581c5a",
  null
  
)

export default component.exports